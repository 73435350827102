* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.cardBox {
  width: 100;
  margin: 0 5px;
  border-radius: 5px;
  border: 1px solid lightgray;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.cardImageBox {
  position: relative;
}

.imgBoxText {
  position: absolute;
  top: 0;
  color: #fff8dd;
  width: 100%;
  padding-right: 5px;
  border: 1px solid white;
  padding: 15px;

  /* align-items: center; */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.imgBoxText span {
  font-size: 11px;
}
.imgBoxText section {
  justify-content: space-between;
  width: 70px;
}

.cardContentParent h6,
.child1 p {
  margin: 0;
}
.cardContentParent {
  padding: 15px 15px;
}
.imgBoxText h6 {
  font-size: 12px;
  margin-bottom: 2px;
}
.imgBoxText p {
  font-size: 11px;
  margin: 0;
  margin-top: 8px;
}
