.dropDownBox {
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 310px;
  position: relative;
  background-color: #fff !important;
  margin-top: 10px;
}
.dropDownSelect {
  display: flex;
  padding: 8px;
}
.dropDownOption {
  border: 1px solid lightgray;
  border-radius: 5px;
  position: absolute;
  width: 310px;
  top: 40px;
  /* padding: 8px; */
  text-align: center;
  background-color: #fff !important;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
}
.dropDownOption span {
  padding: 5px 0;
  border: 1px solid lightgray;
}
