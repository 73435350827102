.heading h2 {
  font-size: 1.8rem;
}
.para {
  color: gray;
  font-weight: 500;
  margin: 0;
}

.locationBox {
  display: flex;
}
.locationContent {
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0 10px;
}
.locationContent span,
.locationContent img {
  display: block;
  font-size: 14px;
}
.ticket {
  color: #35c89b;
  font-size: 18px;
  position: relative;
  left: 10px;
}
.flagBox {
  display: flex;
}
.cirCleImgBox {
  border: 1px solid #35c89b;
  width: 70px;
  height: 70px;
  padding: 2px;
  border-radius: 50%;
}
.cirCleImgBox img{
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.flagBox .organizerIconsBox{
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
}
.actionsBox{
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
}
.actionsBox .btnBox{
  width: 48.5%;
}
.actionsBox .iconBox {
  display: flex;
  width: 48.5%;
}
.actionsBox .iconBox div {
  border: 1px solid #35c89b;
  padding: 7px 10px;
  border-radius: 10px;
  width: 45px;
  cursor: pointer;
}
@media only screen and (max-width: 460px) {
  .actionsBox{
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .actionsBox section{
    /* border: 1px solid red; */
    width: 70% !important;
    margin: 10px 0;
  }
}
@media only screen and (max-width: 340px) {
  .dropDOwnAudioBox {
    flex-direction: column;
  }
  .audioBox {
    margin-bottom: 20px;
  }
  .dropDownBox {
    margin: 0 !important;
  }
}
