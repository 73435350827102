.main p {
  margin: 0;
}
.main {
  max-width: 350px;
  border: 1px solid lightgray;
  margin: 10px 0;
  position: relative;
}
.parent {
  padding: 10px;
}

.routesBox {
  position: absolute;
  z-index: 2;
  bottom: 0;
  background-color: #fff;
  border: 1px solid lightgray;
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.routesBox p {
  font-size: 13px;
  color: gray;
  margin: 3px 0;
}
.routesBox p span {
  color: black;
}
.routesParent {
  display: flex;
  justify-content: space-between;
}
