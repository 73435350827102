.mainContainer {
  width: 45%;
  margin-left: 65px;
  margin-bottom: 50px;
  border-radius: 8px;
}

.mainContainer section {
  margin-bottom: 20px;
}
.heading h2 {
  font-size: 1.7rem;
  font-weight: bold;
}
.circleGroup {
  display: flex;
}
/* @media only screen and (max-width: 1669px) {
  .formBox {
    width: 35%;
    margin-left: 50px;
  }
  .btnBox {
    margin-top: 0px;
  }
  .btnBox button {
    width: 270px;
    margin-top: 40px;
  }
} */
/* @media only screen and (max-width: 1440px) {
  .btnBox button {
    width: 230px;
    margin-top: 40px;
  }
} */
/* @media only screen and (max-width: 1024px) {
  .btnBox button {
    width: 170px;
    margin-top: 40px;
  }
} */
/* @media only screen and (max-width: 965px) {
  .formBox {
    width: 90%;
    margin-left: 50px;
  }
  .btnBox {
    margin-top: 0px;
  }
  .btnBox button {
    width: 400px;
    margin-top: 10px;
  }
} */
/* @media only screen and (max-width: 768px) {
  .formBox {
    width: 90%;
    margin-left: 30px;
  }
  .btnBox {
    margin-top: 0px;
  }
  .btnBox button {
    width: 330px;
    margin-top: 10px;
  }
} */
@media only screen and (max-width: 425px) {
  .mainContainer {
    /* width: 30%; */
    margin-left: 10px;
    margin-top: 10px;
  }
  .heading h2 {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 375px) {
  .heading h2 {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 320px) {
  .heading h2 {
    font-size: 1.2rem;
  }
}
