* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.cardBox {
  width: 97%;
  margin: 0 5px;
  border-radius: 5px;
  border: 1px solid lightgray;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.cardImageBox {
  position: relative;
  cursor: pointer;
  border: 1px solidx;
  width: 100%;
  
}

.imgBoxText {
  position: absolute;
  top: 0;
  color: #fff8dd;
  width: 100%;
  padding-right: 5px;
  border: 1px solid white;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;

  /* align-items: center; */
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.imgBoxText span {
  font-size: 11px;
}
.imgBoxText section {
  justify-content: space-between;
  width: 60px;
}
.imgChild1 {
  position: relative;
  top: 5px;
}
.imgChild2 {
  height: fit-content;
  border: 1px solid white;
  width: 100px !important;
  padding: 4px;
  border-radius: 5px;
  align-items: center;
  position: absolute;
  right: 5px;
}

.child1 {
  /* border: 1px solid red; */
  margin: 0;
  padding: 0;
  /* width: ; */
}
.cardContentParent h6,
.child1 p {
  margin: 0;
}
.cardContentParent {
  padding: 15px 15px;
  height: 70px;
}
.cardContentParent h6 {
  font-size: 12px;
  margin-bottom: 2px;
  font-weight: 800;
}
.child1 p {
  font-size: 11px;
}
.child2 {
  display: flex;
}
.child2 img {
  margin-right: 5px;
}

.child2 span {
  font-size: 12px;
  /* border: 1px solid red; */
  position: relative;
  top: 2px;
  width: 35px;
}
