.mainContainer {
  width: 35%;
  margin-left: 65px;
  margin-bottom: 50px;
  border-radius: 8px;
}

.mainContainer section {
  margin-bottom: 20px;
}
.heading h2 {
  font-size: 1.7rem;
  font-weight: bold;
}
/* .circleGroup {
  display: flex;
} */
.inpBox .selectContainer{
  width: 100%;
}
.inpBox .iconBox{
  position: absolute;
  right: 5%;
}
.muncSelect{
  /* border: 1px solid red; */
  width: 100%;
}
.muncSelect > div{
  border: none;
}
.css-t3ipsp-control:hover{
  border: none !important;
}
.muncSelect svg,
.muncSelect span{
  display: none;
}
.inpBox {
  /* margin: 10px 0; */
  width: 100%;
  border: 1px solid gray;
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
  color: gray;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media only screen and (max-width: 425px) {
  .mainContainer {
    /* width: 30%; */
    margin-left: 10px;
    margin-top: 10px;
  }
  .heading h2 {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 375px) {
  .heading h2 {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 320px) {
  .heading h2 {
    font-size: 1.2rem;
  }
}
