.inpFileParent {
  margin: 20px 0 60px;
  display: flex;
  width: 65%;
  justify-content: space-between;
}

.inpFileBox1 {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.inpFileBox1 label {
  border: 1px dashed gray;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 150px;
  border-radius: 50%;
  display: flex;
}

.inpFileBox1 label input {
  border: 1px solid red;
  width: 100%;
  height: 150px;
  opacity: 0;
  position: absolute;
  z-index: 99 !important;
  border-radius: 50%;
  cursor: pointer;
}

.inpFileBox2 {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.inpFileBox2 label {
  border: 1px dashed gray;
  cursor: pointer;
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2px;
}

.inpFileBox2 label input {
  width: 100%;
  height: 150px;
  opacity: 0;
  position: absolute;
  z-index: 99 !important;
  border-radius: 50%;
  cursor: pointer;
}

.downloadIcon {
  position: absolute;
  top: 40%;
  left: 39%;
  font-size: 1.8rem;
  z-index: 1;
  color: gray;
}

.modalInpFileBox1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.modalInpFileBox1 label div div {
  border-radius: 50% !important;
  background-color: #fff !important;
}

.modalInpFileBox1 label div canvas {
  border-radius: 50% !important;
}

.modalInpFileBox2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.modalInpFileBox2 label {
  border: 1px dashed gray;
  cursor: pointer;
  width: 170px;
  height: 170px;
  border-radius: 10px;
  position: relative;
}

.modalInpFileBox2 label .cropperDownloadIcon {
  position: absolute;
  top: 40%;
  left: 40%;
  font-size: 1.8rem;
  z-index: 1;
  color: gray;
}

.modalInpFileBox2 label input {
  opacity: 0;
  z-index: 99 !important;
}

.titleDescBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30;
  width: 100%;
}

.titleDescBox input {
  display: block;
  width: 50%;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;
  border: 1px solid gray;
  font-size: 1rem;
  color: gray;
}

.inpFileBox2 label .previewImg {
  width: 100%;
  position: absolute;
  z-index: 99 !important;
  border-radius: 0;
  cursor: pointer;
}
