.leaflet-container {
  width: 100%;
  height: 600px;
}
.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cluster-markere {
  color: #fff;
  background: #c12ac3;
  border-radius: 50%;
  padding: 10px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cluster-markeres {
  color: #fff;
  background: #b96049;
  border-radius: 50%;
  padding: 10px;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
