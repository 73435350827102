.eventHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.eventHeader button {
  border: none;
  background: transparent;
  color: #35c89b;
  font-weight: 500;
}
@media only screen and (max-width: 480px) {
  .eventHeader button {
    display: none;
  }
  .eventHeader {
    justify-content: center;
  }
}
