.swiper {
    /* border: 2px solid red; */
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    /* border: 1px solid red; */
    height: 55%;
    width: 100%;
}

.secondaryMySwiper2 {
    /* border: 1px solid red; */
    height: 50%;
    width: 100%;
}

.mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.6;
}

.secondaryMySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.secondaryMySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.6;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: #b5b8b8;
    padding: 20px;
    opacity: 0.8;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 19px;
    font-weight: 900;
}