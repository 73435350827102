.loginBox {
  display: flex;
  /* height: 100vh; */
}
.imgBox {
  width: 50%;
  height: 110vh;
  display: flex;
  justify-content: center;
  align-items: center; /* background-color: #f5fffc; */
}
.imgBox img {
  max-width: 100%;
  height: 100%;
  width: 100%;
}
.formBox {
  width: 55%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f2f2f2; */
}
@media only screen and (max-width: 768px) {
  .loginBox {
    flex-direction: column;
    height: 110vh;
  }
  .imgBox {
    width: 100%;
    height: 50vh;
  }
  .formBox {
    width: 100%;
    margin-bottom: 20px;
  }
  .formBox form {
    width: 90%;
    align-items: center;
    justify-content: center;
  }
}
/* @media only screen and (max-width: 425px) {
  .imgBox img {
    height: 60vh;
  }
  .loginBox {
    height: 90vh;
  }
  .formBox {
    margin-top: 150px;
  }
}
@media only screen and (max-width: 375px) {
  .imgBox img {
    height: 60vh;
  }

  .formBox {
    margin-top: 110px;
  }
} */
