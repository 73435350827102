.linkBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  /* border-radius: 9px 9px 0px 0px; */
  /* border-bottom: 1px solid #a09f9f; */
  /* color: white;
  background-color: #34c89a; */
  /* padding: 0px 25px; */
}
.iconBox {
  display: flex;
  align-items: center;
}
.iconBox span {
  margin-left: 15px;
}
