.parent {
  width: 240px;
  display: flex;
  border: 1px solid lightgray;
  align-items: center;
  justify-content: space-around;
  padding: 3px;
  border-radius: 4px;
}

.playPauseIcon button {
  border: none;
  background-color: #35c89b;
  padding: 5px;
  padding-top: 6px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playPauseIcon button svg {
  font-size: 1.3em;
  position: relative;
  left: 1px;
  bottom: 1px;
}

.preogressParetn {
  border: 1px solid lightgray;
  width: 150px;

  height: 1px;
}

.preogressParetn div {
  border: 1px solid #35c89b;
  background-color: #35c89b;
  height: 5px;
  border-radius: 5px;
  position: relative;
  bottom: 3px;
}

.time {
  /* border: 1px solid red; */
  font-size: 0.8em;
}

@media only screen and (max-width : 456px) {
  .parent {
    width: 100%;
  }
}