.dropDownAndAudio {
  /* border: 1px solid red; */
  display: flex;
}
.cardParent {
  border: 1px solid red;
  display: flex;
}
.responsive {
  display: none;
}
@media only screen and (max-width: 456px) {
  .responsive {
    display: block;
  }
  .inResponsive {
    display: none;
  }
}
