.multi-range-slider .thumb::before {
  border: none !important;
  box-shadow: none;
}
.multi-range-slider {
  border: none !important;
}
.multi-range-slider .bar-inner {
  border: none;
  outline: none;
  box-shadow: none;
}

.thumb-left {
  display: none !important;
}
