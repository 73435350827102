* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.cardBox {
  width: 262;
  margin: 0 5px;
  border-radius: 12px;
  border: 1px solid lightgray;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  position: relative;
}
.cardImageBox {
  position: relative;
  width: 100%;
  border-radius: 12px 12px 0 0;
  background-color: rgba(0, 0, 0, 0.8);
}
.cardImageBox img {
  border-radius: 12px 12px 0 0;
}
.imgBoxText {
  position: absolute;
  top: 80%;
  bottom: 0;
  color: #fff8dd;
  width: 100%;
  height: 100%;
  padding-right: 10px;
}
.imgBoxText p {
  margin: 0;
  width: 100%;
  text-align: right;
  font-size: 12px;
  font-weight: 100;
}
.locationBOx {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
.locationBOx span {
  font-size: 12px;
}
.child1 {
  /* border: 1px solid red; */
  margin: 0;
  padding: 0;
  /* width: ; */
}
.cardContentParent h6,
.child1 p {
  margin: 0;
}
.cardContentParent {
  padding: 15px 15px;
  cursor: pointer;
  height: 90px;
}
.cardContentParent h6 {
  font-size: 12px;
  margin-bottom: 2px;
  font-weight: 800;
  height: 15px;
  overflow: hidden;
}
.child1 p {
  font-size: 12px;
  height: 20px;
  overflow: hidden;
}
.child2 {
  display: flex;
}
.child2 img {
  margin-right: 5px;
}

.child2 span {
  font-size: 12px;
  /* border: 1px solid red; */
  position: relative;
  top: 2px;
  width: 35px;
}
.cardBox .fvrtIconBox {
  /* border: 1px solid red; */
  position: absolute;
  z-index: 99;
  right: 2%;
  top: 2%;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .cardBox {
    width: 97%;
  }
}
