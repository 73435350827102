.child1 {
  width: 45%;
}
.child2 {
  width: 50%;
}
@media only screen and (max-width: 992px) {
  .parent {
    flex-direction: column;
  }
  .child1 {
    width: 100%;
    margin-bottom: 50px;
  }
  .child2 {
    width: 100%;
  }
}
