.mainBox{
    /* border: 1px solid red; */
    width: 40%;
}
.optionsContainer{
    /* border: 1px solid red; */
    margin-top: 40px;
}
.optionsContainer .optionBox{
    /* border: 1px solid blue; */
    border-bottom: 2px solid gray;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.optionsContainer .optionBox h4{
    font-weight: bold;
    font-size: 20px;
}