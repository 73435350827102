.btn {
  background-color: #34c89a;
  border: 1px solid #34c89a;
  padding: 8px;
  color: #fff;
  border-radius: 8px;
}
.btn svg {
  /* border: 1px solid red; */
  position: relative;
  right: 2px;
  bottom: 1px;
  font-size: 1.2em;
}
