.cardBox {
    /* border: 1px solid red; */
    width: 700px;
}

.cardBox .customCard {
    /* border: 1px solid green; */
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
}

.cardBox .customCard .imageBox {
    /* border: 1px solid blue; */
    width: 35%;
}

.cardBox .customCard .imageBox img {
    border-radius: 10px;
}

.cardBox .customCard .textBox {
    /* border: 1px solid blue; */
    width: 60%;
}

/* Responsive */
@media only screen and (max-width:726px){
    .cardBox{
        /* border: 1px solid red; */
        width: 100%;
    }
}