.listContainer {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #8d8d8d;
  padding: 10px 0px;
}
.listDiv {
  display: flex;
  align-items: center;
}
.listDiv span {
  margin-left: 10px;
  color: #8d8d8d;
}
.listContainer .delIcon {
  cursor: pointer;
}