.nav_parent {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 30px;
}

.logo {
  display: flex;
}

.logo img {
  cursor: pointer;
}

.navlinks ul {
  display: flex;
  list-style-type: none;
  gap: 20px;
  position: relative;
  top: 7px;
}

.navlinks ul li {
  cursor: pointer;
  font-weight: 600 !important;
  text-transform: capitalize;
}

.menu_bar {
  display: none;
}

.sidebar_parent {
  visibility: hidden;
  display: none;
}

.nav_btn_box {
  display: flex;
  justify-content: space-between;
  width: 270px;
}

@media only screen and (max-width: 900px) {
  .nav_parent {
    padding: 10px 20px;
  }

  .navlinks {
    display: none;
  }

  .menu_bar {
    display: block;
    margin-right: 10px;
  }

  .menu_bar svg {
    font-size: 1.4em;
    cursor: pointer;
  }

  .sidebar_parent {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    /* display: block; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .sidebar_parent ul {
    list-style-type: none;
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .sidebar_parent ul li {
    color: #fff;
    position: relative;
    right: 15px;

    margin: 0 auto;
    cursor: pointer;
  }

  .arrowUp {
    color: #000 !important;
  }

  .arrowUp svg {
    font-size: 1.5em;
    cursor: pointer;
  }

  .vicon {
    /* width: 56px !important; */
    cursor: pointer;
  }
}

@media only screen and (max-width: 500px) {
  .nav_parent {
    justify-content: space-between;
  }
  .logo img {
    width: 110px;
    height: 20px;
    position: relative;
    top: 2px;
    left: 5px;
  }

  .menu_bar {
    position: relative;
    bottom: 2px;
  }

  .nav_btn_box button {
    width: 100px !important;
    padding: 5px;
  }

  .nav_btn_box {
    width: 45%;
  }
}
@media only screen and (max-width: 410px) {
  .nav_btn_box {
    width: 50%;
  }
  .nav_btn_box button {
    width: 70px !important;
    font-size: 12px;
  }
}
