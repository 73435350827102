.parent {
  width: 80%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  margin: 20px auto;
}
.child1 {
  width: 30%;
}
.child2 {
  width: 66%;
}
@media only screen and (max-width: 992px) {
  .parent {
    flex-direction: column;
  }
  .child1 {
    width: 100%;
  }
  .child2 {
    width: 106%;
  }
}
