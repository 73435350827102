.mainContainer {
  width: 25%;
  /* height: 76.3vh; */
  /* border: 1px solid red; */
  border: 1px solid #a09f9f;
  border-radius: 5px;
  margin-left: 45px;
  margin-right: 15px;
  margin-bottom: 50px;
  height: 332px;
}

.linkBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 9px 9px 0px 0px;
  /* border-bottom: 1px solid #a09f9f; */
  /* color: white;
  background-color: #34c89a; */
}
.iconBox {
  display: flex;
  align-items: center;
}
.iconBox span {
  margin-left: 15px;
}
@media only screen and (max-width: 965px) {
  .mainContainer {
    width: 90%;
    margin-left: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .mainContainer {
    width: 90%;
    margin-left: 25px;
  }
}
@media only screen and (max-width: 425px) {
  .mainContainer {
    width: 90%;
    margin-left: 25px;
  }
}
@media only screen and (max-width: 375px) {
  .mainContainer {
    width: 90%;
    margin-left: 15px;
  }
}
