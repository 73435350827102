.mainContainer {
  /* margin-left: 300px; */
}
.heading {
}
.heading h2 {
  font-size: 1.6rem;
  font-weight: bold;
}
.imgSection {
  padding: 10px 20px;
  width: 100%;
  border: 1px dashed grey;
}
.imgBox {
  margin-right: 30px;
}
.imagesContainer {
  display: flex;
}
.imgBox img {
  width: 138px;
  height: 15vh;
  border-radius: 10px;
}
.inpSection {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.inpBox {
  /* margin: 10px 0; */
  width: 48%;
  /* height: 37px; */
  border: 1px solid gray;
  padding: 1px;
  border-radius: 5px;
  outline: none;
  color: gray;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inpBox input {
  /* height: 37px !important; */
}

/* File Input */
.inpFileBox {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.inpFileBox label {
  border: 1px dashed gray;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 15vh;
  border-radius: 10px;
  display: flex;
}

.inpFileBox label input {
  border: 1px solid red;
  width: 100%;
  height: 150px;
  opacity: 0;
  position: absolute;
  z-index: 99 !important;
  border-radius: 50%;
  cursor: pointer;
  /* padding: 5px; */
}
.uploadIcon {
  position: absolute;
  top: 20%;
  left: 39%;
  font-size: 1.8rem;
  z-index: 1;
  color: gray;
}
.muncSelectContainer{
  /* margin: 10px 0; */
  width: 48%;
  /* height: 37px; */
  /* border: 1px solid gray; */
  /* padding: 10px; */
  border-radius: 5px;
  outline: none;
  color: gray;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.muncSelect{
  width: 100%;
}
.checkBoxContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.checkBox {
  width: 26px;
  background-color: #34c89a;
  text-align: center;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.checkBox2 {
  width: 26px;
  background-color: white;
  text-align: center;
  color: #34c89a;
  border: 1px solid #34c89a;
  border-radius: 5px;
  cursor: pointer;
}
.textAreaSection {
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
  /* border: 1px solid red; */
}
.textAreaSection textarea{
  width: 100%;
  padding: 10px;
  height: 20vh;
  border-radius: 5px;
  border: 1px solid grey;
}
.locationInpBox{
  margin-top: 40px;
  width: 100%;
  /* height: 37px; */
  border: 1px solid gray;
  padding: 1px;
  border-radius: 5px;
  outline: none;
  color: gray;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.termsAndCondition {
  margin-left: 10px;
  text-decoration: none;
  color: grey;
  font-size: 0.9rem;
  font-weight: 700;
}
.termsAndCondition span {
  margin-left: 5px;
  color: black;
  text-decoration: underline;
}

@media only screen and (max-width: 425px) {
  .imagesContainer {
    flex-wrap: wrap;
  }
  .imgBox {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 375px) {
  .imagesContainer {
    flex-wrap: wrap;
  }
  .imgBox img {
    width: 124px;
    height: 16vh;
  }
}
@media only screen and (max-width: 320px) {
  .imagesContainer {
    flex-wrap: wrap;
  }
  .imgBox img {
    width: 240px;
    height: 23vh;
  }
}
