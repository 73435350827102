input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inpBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.inpBox input,
.inpBox select {
  display: block;
  width: 48.5%;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;
  border: 1px solid gray;
  font-size: 1rem;
  color: gray;
}

.inpBox input::placeholder {
  font-size: 0.8rem;
}

.inpBox textarea {
  display: block;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;
  border: 1px solid gray;
  font-size: 1rem;
  color: gray;
  resize: none;
  height: 100px;
}

.checkBox {
  border: 1px solid black;
  width: 18px;
  height: 18px;
  position: relative;
  top: 3px;
  margin-right: 10px;
  border-radius: 5px;
  padding: 3px;
  accent-color: rgb(53, 200, 155);
  background-color: #fff;
}

/* Select Styles */
.selectContainer {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.selectContainer .selectBox {
  display: block;
  width: 48.5%;
  border-radius: 5px;
  outline: none;
  border: 1px solid gray;
  font-size: 1rem;
  color: gray;
}

.inpSelect {
  display: flex;
  justify-content: space-between;
}
.inpSelect input {
  width: 48.5%;
  padding: 10px 20px;
  border-radius: 5px;
  outline: none;
  border: 1px solid gray;
  font-size: 1rem;
  color: gray;
}

.citySelect {
  width: 48.5%;
  border-radius: 5px;
  outline: none;

  /* border: 1px solid gray; */
  font-size: 1rem;
  color: gray;
}

/* Responsive Styling */
@media only screen and (max-width : 456px) {
  .selectContainer {
    /* border: 1px solid red; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
  }
  .selectContainer .selectBox {
    margin: 5px 0;
    width: 100%;
  }
}
