.input {
  width: 100%;
  /* border: 1px solid gray; */
  padding: 10px;
  /* border-radius: 5px; */
  border: none;
  outline: none;
  color: gray;
  font-size: 14px;
}
.input::placeholder {
  color: gray;
}
.input:disabled {
  background-color: #fff;
}
