.loginBox {
  display: flex;
  /* height: 100vh; */
  /* border: 1px solid red; */
}
.imgBox {
  width: 50%;
  height: 110vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f5fffc; */
}
.imgBox img {
  max-width: 100%;
  height: 100%;
  width: 100%;
}
.formBox {
  /* border: 1px solid red; */
  width: 55%;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .loginBox {
    flex-direction: column;
  }
  .imgBox {
    width: 100%;
    height: 50vh;
  }
  .formBox {
    width: 100%;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width : 400px){  
  .imgBox {
    height: 35vh;
  }
}
/* @media onXly screen and (max-width: 768px) {
  .loginBox {
    flex-direction: column;
  }
  .imgBox {
    width: 100%;
    height: 25vh;
  }
  .formBox {
    width: 100%;
    height: 169vh;
  }
  .loginBox {
    height: 170vh;
  }
  .formBox form {
    width: 90%;
    margin-top: 200px;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 425px) {
  .imgBox img {
    height: 60vh;
  }
  .formBox {
    height: 140vh;
  }
  .loginBox {
    height: 140vh;
  }
  .formBox form {
    margin-top: 80px;
  }
  
}
@media only screen and (max-width: 375px) {
  .imgBox img {
    height: 60vh;
  }

  .formBox {
    height: 140vh;
  }
  .loginBox {
    height: 140vh;
  }

  .formBox form {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 320px) {
  .formBox form {
    margin-top: 90px;
  }
} */
