.mainDiv {
  display: flex;
  /* justify-content: space-between; */
  min-height: 69vh;
}
@media only screen and (max-width: 965px) {
  .mainDiv {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .mainDiv {
    display: block;
  }
}
@media only screen and (max-width: 425px) {
  .mainDiv {
    display: block;
  }
}
@media only screen and (max-width: 375px) {
  .mainDiv {
    display: block;
  }
}
