.slider {
  width: 100%;
  padding: 10px 0;
}
.unSliderCard {
  width: 25%;
}
@media only screen and (max-width: 1024px) {
  .unSliderCard {
    width: 50%;
  }
}
@media only screen and (max-width: 558px) {
  .unSliderCard {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .nextBtn {
    position: absolute;
    right: 15px;
    top: 50%;
  }
  .preBtn {
    position: absolute;
    top: 50%;
    left: 15px;
  }
  .slider {
    padding: 0 10px;
  }
}
