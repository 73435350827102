.formBox {
  width: 35%;
  margin-left: 65px;
  margin-bottom: 50px;
  /* padding: 0px 25px; */
  border-radius: 8px;
}
.formBox section {
  margin-bottom: 20px;
}
.inpBox {
  /* margin: 10px 0; */
  /* width: 100%; */
  /* width: 600px; */
  border: 1px solid gray;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  color: gray;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading {
  display: flex;
  justify-content: space-between;
}
.Link {
  text-decoration: none;
  color: black;
  font-size: 0.9rem;
}
.Link span {
  color: #34c89a;
  font-weight: bold;
}
.checkBox {
  width: 26px;
  background-color: #34c89a;
  text-align: center;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.checkBox2 {
  width: 26px;
  background-color: white;
  text-align: center;
  color: #34c89a;
  border: 1px solid #34c89a;
  border-radius: 5px;
  cursor: pointer;
}
.checkBoxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.termsAndCondition {
  margin-left: 10px;
  text-decoration: none;
  color: grey;
  font-size: 0.9rem;
  font-weight: 700;
}
.termsAndCondition span {
  margin-left: 5px;
  text-decoration: underline;
}
.switchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.para {
  font-weight: 700;
  font-size: 1.2rem;
}

.btnBox {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.btnBox button {
  width: 317px;
}
@media only screen and (max-width: 1669px) {
  .formBox {
    width: 35%;
    margin-left: 50px;
  }
  .btnBox {
    margin-top: 0px;
  }
  .btnBox button {
    width: 270px;
    margin-top: 40px;
  }
}
@media only screen and (max-width: 1440px) {
  .btnBox button {
    width: 230px;
    margin-top: 40px;
  }
}
@media only screen and (max-width: 1024px) {
  .btnBox button {
    width: 170px;
    margin-top: 40px;
  }
}
@media only screen and (max-width: 965px) {
  .formBox {
    width: 90%;
    margin-left: 50px;
  }
  .btnBox {
    margin-top: 0px;
  }
  .btnBox button {
    width: 400px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .formBox {
    width: 90%;
    margin-left: 30px;
  }
  .btnBox {
    margin-top: 0px;
  }
  .btnBox div {
    margin-top: 0px;

    width: 100%;
  }
  .btnBox button {
    width: 100%;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 425px) {
  .formBox {
    width: 90%;
    margin-left: 30px;
  }
  .btnBox {
    margin-top: 0px;
  }
  .btnBox button {
    width: 100%;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 375px) {
  .formBox {
    margin-left: 20px;
  }
  /* .btnBox {
    margin-top: 0px;
    display: block;
  }
  .btnBox button {
    width: 320px;
    margin-top: 10px;
  } */
}
@media only screen and (max-width: 320px) {
  .btnBox {
    display: block;
  }
}
