.parent p {
  margin: 0;
}
.parent {
  border: 1px solid lightgray;
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  margin: 3px 0;
}
.child {
  display: flex;
  justify-content: space-between;
  align-items: first baseline;
}
.child h5 {
  font-weight: bolder;
  font-size: 1rem;
}
.child p span {
  margin: 5px;
}
.child p span {
  font-size: 14px;
  color: #35c89b;
}
.child p small {
  font-size: 14px;
  color: gray;
}
