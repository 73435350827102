.mainDiv {
  border-radius: 5px;
  width: 40%;
  height: 25vh;
  margin: 10px;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.mainDiv .textDiv{
  /* border: 1px solid red; */
}