.circleContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 20px;
  cursor: pointer;
  /* width: 60px; */
}
.circleContainer h6 {
  font-weight: bold;
  margin-top: 10px;
}
.circleIcon {
  width: 79px;
  padding: 4px;
  border-radius: 50%;
  background-color: #34c89a;
  color: white;
}

@media only screen and (max-width: 425px) {
  .circleIcon {
    width: 55px;
    padding: 12px;
    border-radius: 50%;
    background-color: #34c89a;
    color: white;
  }
  .circleIcon svg {
    font-size: 1.9rem !important;
  }
}
@media only screen and (max-width: 320px) {
  .circleIcon {
    width: 40px;
    padding: 8px;
    border-radius: 50%;
    background-color: #34c89a;
    color: white;
  }
  .circleIcon svg {
    font-size: 1.5rem !important;
  }
}
