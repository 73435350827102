.heading h2 {
  font-size: 1.8rem;
}
.para {
  color: gray;
  font-weight: 500;
  margin: 0;
}

.secondarySliderParent {
  /* border: 1px solid red; */
  margin-bottom: -120px;
  height: 400px;
}

.locationBox {
  display: flex;
}
.locationContent {
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0 10px;
}
.locationContent span,
.locationContent img {
  display: block;
  font-size: 14px;
}
.ticket {
  color: #35c89b;
  font-size: 18px;
  position: relative;
  left: 10px;
}
.flagBox {
  display: flex;
  margin-top: 20px;
}
.cirCleImgBox {
  border: 1px solid #35c89b;
  width: 70px;
  height: 70px;
  padding: 2px;
  border-radius: 50%;
  cursor: pointer;
}
.cirCleImgBox img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.iconBox {
  display: flex;
}
.iconBox div {
  border: 1px solid #35c89b;
  padding: 7px 10px;
  border-radius: 10px;
  width: 45px;
  cursor: pointer;
}
@media only screen and (max-width: 340px) {
  .dropDOwnAudioBox {
    flex-direction: column;
  }
  .audioBox {
    margin-bottom: 20px;
  }
  .dropDownBox {
    margin: 0 !important;
  }
}
