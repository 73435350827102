.formBox {
  width: 80%;
  border-radius: 8px;
  /* background-color: #fff; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  margin: 50px auto 0;
}
.inpBox {
  border: 1px solid gray;
  padding: 2px 10px;
  border-radius: 5px;
  margin: 15px 0;
  outline: none;
  color: gray;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.heading {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.Link {
  text-decoration: none;
  color: black;
  font-size: 0.9rem;
}
.Link span {
  color: #34c89a;
  font-weight: bold;
}
.checkBox {
  width: 26px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #34c89a;
}

.checkBoxContainer {
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
}

.termsAndCondition {
  margin-left: 10px;
  text-decoration: none;
  color: grey;
  font-size: 0.9rem;
  font-weight: 700;
}
.termsAndCondition span {
  margin-left: 5px;
  text-decoration: underline;
}
.switchContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  font-weight: 600;
}
.para {
  font-weight: 700;
  font-size: 1.2rem;
}
@media only screen and (max-width: 1106px) {
  .heading {
    flex-direction: column;
  }
}
@media only screen and (max-width: 768px) {
  .heading {
    flex-direction: column;
  }
}
@media only screen and (max-width: 375px) {
  .heading {
    flex-direction: column;
  }
}
