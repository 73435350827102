.radioBox {
  /* border: 1px solid red; */
  padding: 5px;
  width: 100%;
}

.radioBox label {
  /* border: 1px solid red; */
  display: flex;
  justify-content: start;
  align-items: center;
  text-transform: capitalize;
}

.radioBox label .iconBox {
  /* border: 1px solid red; */
  display: flex;
  /* justify-content: space-between; */
  gap: 8px;
  align-items: center;
  /* margin-right: 1px; */
  padding: 0 5px;
  /* width: 60px; */
}

/* unchecked radio button style */
input[type="radio"] {
  appearance: none;
  border: 2px solid #ccc;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  padding: 6px;
}

/* checked radio button style */
input[type="radio"]:checked {
  background-color: #34c89a;
  border-color: #34c89a;
}
