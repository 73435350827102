.parent {
  display: flex;
}
.child1 {
  width: 60%;
  padding: 20px;
}
.child2 {
  width: 40%;
  padding: 25px;
}
.headingBox {
  padding: 0 25px;
}
.inpBoxP {
  border: 1px solid lightgray;
  display: flex;
  align-items: flex-start;
  padding: 8px 12px;
  border-radius: 8px;
  margin: 10px 0;
}
.inpBox {
  width: 100%;
}
.inpBox input,
textarea {
  width: 100%;
  border: none;
  outline: none;
  padding-left: 10px;
  color: gray;
  resize: none;
}
.inpBox input::placeholder,
textarea::placeholder {
  font-size: 14px;
}
.inpBox textarea {
  height: 150px;
}

.contactCards {
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 113px;
}
.contactDetailsImg {
  width: 55px;
  position: relative;
  bottom: 7px;
  margin-right: 8px;
  margin-left: 15px;
}
.contactDetails {
  width: 60%;
  padding-left: 10px;
}
.contactDetails h3 {
  font-size: 1rem;
  font-weight: bold;
}
.contactDetails p {
  font-size: 0.9rem;
  color: gray;
}
@media only screen and (max-width: 767px) {
  .parent {
    flex-direction: column;
  }
  .child1,
  .child2 {
    width: 100%;
    padding: 20px;
  }
}
.contactCards {
  min-height: 150px;
}
