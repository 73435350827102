.mainBox {
  width: 45%;
  margin-left: 65px;
  margin-bottom: 50px;
}

.progressText {
  display: flex;
  width: 57%;
  justify-content: space-between;
}
.progressText span {
  display: block;
  font-size: 13px;
  position: relative;
  bottom: 3px;
}
.progressbar {
  border: 2px solid gray;
  border-radius: 4px;
  margin-top: 5px;
  width: 90%;
}
.progressbarComp {
  border: 2px solid #35c89b;
  border-radius: 4px;
  width: 55%;
  position: relative;
  bottom: 4px;
}
.progressbarDots {
  display: flex;
  width: 55%;
  justify-content: space-between;
  position: relative;
  bottom: 14px;
}
.multiRangeSlider {
  border: none !important;
  box-shadow: none !important;
}
