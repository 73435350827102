.inpFileParent {
  /* border: 1px solid red; */
  margin: 20px 0 60px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.morePointsBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modalInpFileBox1 {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.modalInpFileBox1 label {
  border: 1px dashed gray;
  cursor: pointer;
  width: 170px;
  height: 170px;
  border-radius: 10px;
  position: relative;
}

.modalInpFileBox1 label .cropperDownloadIcon {
  position: absolute;
  top: 40%;
  left: 40%;
  font-size: 1.8rem;
  z-index: 1;
  color: gray;
}

.modalInpFileBox1 label img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.modalInpFileBox1 label input {
  opacity: 0;
  z-index: 99 !important;
}

/* .modalInpFileBox1 label{
  border: 1px solid red;
  width: 300px;
  height: 300px;
} */
.modalInpFileBox1 label div div {
  border-radius: 50% !important;
  background-color: #fff !important;
}

.modalInpFileBox1 label div canvas {
  border-radius: 50% !important;
}


.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}