.parent {
  width: 81%;
  display: flex;
  justify-content: space-between;
}
.child1 {
  width: 35%;
}
.child2 {
  width: 60%;
}
@media only screen and (max-width: 1000px) {
  .parent {
    flex-direction: column;
  }
  .child1,
  .child2 {
    width: 100%;
    margin: 10px 0;
  }
}
