.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-image: url("../../Assets/fun2.png");
}

.child {
  width: 80%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
}
.child h3 {
  font-weight: bold;
}
.inpParent {
  display: flex;
  justify-content: space-between;
}
.inpParent section {
  display: flex;
  align-items: center;
  border: 1px solid lightgrey;
  width: 30%;
  border-radius: 8px;
  padding: 10px;
}
.inpParent input {
  width: 100%;
  border: none;
  outline: none;
  padding: 0px 15px;
}
.inpParent section svg {
  color: gray;
}
.howToPlan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin: 15px 0;
  flex-wrap: wrap;
}

.howToPlan section {
  width: 30%;
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 10px;
}
.howToPlan h6 {
  font-weight: bold;
  font-size: 14px;
}
.howToPlan section aside {
  display: flex;
  flex-wrap: wrap;
}
.howToPlan section span {
  font-size: 14px;
}
.howToPlan small{
  font-size: 12px;
}

.btnBox {
  padding: 0 !important;
  border: none !important;
}
.btnBox button {
  padding: 20px 0;
}
.locationBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.locationBox section {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.locationBox section span {
  color: gray;
  margin: 10px;
}
.btnBoxEnd {
  position: relative;
  top: 40px;
}
.btnBoxEnd button {
  padding: 10px 20px;
}

@media only screen and (max-width: 767px) {
  .parent {
    height: 100%;
  }
  .child {
    margin: 40px 0;
  }
  .inpParent,
  .howToPlan,
  .locationBox {
    flex-direction: column;
  }
  .howToPlan {
    width: 100% !important;
  }
  .inpParent section,
  .howToPlan section {
    width: 100% !important;
    margin: 5px 0;
  }
}
