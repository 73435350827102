.parent {
  /* border: 1px solid red; */
  width: 25%;
  /* max-width: 20%; */
  min-width: 220px;
  position: relative;
  margin: 10px auto;
  /* padding: 0; */
}

.child1 {
  width: 100%;
  border-radius: 20px;
  padding: 0;
  height: 350px;
}

.child1 img {
  border: 1px solid #35c89b;

  border-radius: 22px;
}
.child2 {
  border-radius: 20px;
  padding: 20px 15px;
  position: absolute;
  top: 0;
  color: #fff;
  width: 92%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.28);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.child2 p {
  font-size: 12px;
}
@media only screen and (max-width :1199px) {
  .child2{
    width: 90%;
  }
}
@media only screen and (max-width: 456px) {
  .parent {
    padding: 0;
    margin: 10px auto;
    width: 90%;
  }
  .child2 {
    width: 100%;
  }
}
