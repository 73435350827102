.header_box,
.header_other {
  padding: 20px 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  position: relative;
  bottom: 45px;
}

.header_box_1 {
  background: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;
  width: 170px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 55px;
}
.header_box_1 img {
  margin: 0 10px;
  /* margin-right: 20px; */
}
.box_one_text {
  font-size: 12px;
}
.inpBox .selectContainer {
  width: 100%;
}
.inpBox .iconBox {
  position: absolute;
  right: 5%;
}
.muncSelect {
  /* border: 1px solid red; */
  width: 100%;
}
.muncSelect > div {
  border: none;
  background-color: #fff;
}
.muncSelect .css-13cymwt-control {
  box-shadow: none;
}
.css-t3ipsp-control:hover {
  /* border: none !important; */
}
.muncSelect svg {
  display: block;
  color: #fff;
  border: 1px solid #35c89b;
  background-color: #35c89b;
  border-radius: 50%;
}
.muncSelect span {
  display: none;
}
.inpBox {
  /* margin: 10px 0; */
  width: 100%;
  border: 1px solid lightgray;
  padding: 5px 10px;
  border-radius: 5px;
  outline: none;
  color: gray;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.inp {
  border: none;
  outline: none;
  font-size: 12px;
  width: 80%;
}
.searchIcon {
  position: relative;
  top: 2px;
}
/* .multiRangeContainer{
  border: 1px solid red;
} */
.progressText {
  display: flex;
  width: 57%;
  justify-content: space-between;
}
.progressText span {
  display: block;
  font-size: 13px;
  position: relative;
  bottom: 3px;
}
.progressbar {
  border: 2px solid gray;
  border-radius: 4px;
  margin-top: 5px;
  width: 90%;
}
.progressbarComp {
  border: 2px solid #35c89b;
  border-radius: 4px;
  width: 55%;
  position: relative;
  bottom: 4px;
}
.progressbarDots {
  display: flex;
  width: 55%;
  justify-content: space-between;
  position: relative;
  bottom: 14px;
}
.multiRangeSlider {
  border: none !important;
  box-shadow: none !important;
}

.circle {
  width: 17px;
  display: block;
  border-radius: 50%;
  height: 17px;
  background-color: #35c89b;
}
@media only screen and (max-width: 992px) {
  .header_box,
  .header_other {
    width: 90%;
    margin: 0 auto;
  }
  .header_box_1 {
    width: 100%;
  }
  .searchIcon {
    margin-left: 5px;
  }
  .inp {
    width: 85%;
  }
}
@media only screen and (max-width: 500px) {
  .header_box {
    bottom: 170px;
  }
  .progressbar {
    width: 100%;
  }
  .progessParent {
    padding: 5px 10px 0 !important;
    position: relative;
    top: 2px;
  }
}
