.tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
  margin: 10px 0;
}

.tabs li {
  padding: 10px;
  letter-spacing: 1px;
  cursor: pointer;
}
