.mainBox{
    /* border: 1px solid red; */
}
.mainBox .nav-tabs .nav-link{
    border: none;
    color: #000;
    font-weight: bold;
}
.mainBox .nav-tabs .nav-link.active{
    border-bottom: 2px solid #34c89a !important;
    background-color: #fff !important;
    color: #34c89a;
}