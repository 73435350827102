.mainBox{
    /* border: 1px solid red; */
    margin-left: 10px;
}
/* .headingBox{
    border: 1px solid red;
} */
.logoBox{
    /* border: 1px solid red; */
    margin: 30px 0;
    width: 40%;
}
.reviewBox{
    /* border: 1px solid blue; */
    padding: 10px 0;
}
.reviewBox .subHeadingBox{
    /* border: 1px solid red; */
    margin: 15px 0;
}
.reviewBox .starBox{
    /* border: 1px solid yellow; */
    margin: 20px 0;
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reviewBox .textAreaContainer{
    /* border: 1px solid green; */
}
.reviewBox .textAreaContainer textarea{
    width: 100%;
    height: 150px;
    border-radius: 10px;
    padding: 10px;
}
.reviewBox .rateBtn{
    /* border: 1px solid red; */
    margin-top: 20px;
}