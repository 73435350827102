.sliderParent {
  /* border: 1px solid red; */
  /* max-width: 400px; */
  height: 700px;
  margin-bottom: -140px;
  position: relative;
}
.videoIcon {
  position: absolute;
  z-index: 999;
  width: 50px;
  right: 40px;
  top: 20px;
}
.secondarySliderParent {
  /* border: 1px solid red; */
  margin-bottom: -120px;
  height: 400px;
}
/* .sliderParent img {
  border-radius: 8px;
} */
.sliderParent .videoBox {
  /* border: 1px solid red; */
  width: 100%;
}
.sliderParent .videoBox video {
  /* border: 1px solid red; */
  /* border-radius: 8px; */
  width: 100%;
  height: 350px;
}
.sliderParent .thumbnailVideo {
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.sliderParent .thumbnailVideo video {
  width: 100%;
}
.imagesbox {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.imagesbox section {
  width: 24%;
}

/* Slider Styling */

.slider {
  width: 100%;
  padding: 10px 0;
  display: block;
  /* justify-content: center; */
}

@media only screen and (max-width: 576px) {
  .nextBtn {
    position: absolute;
    right: 15px;
    top: 50%;
  }

  .preBtn {
    position: absolute;
    top: 50%;
    left: 15px;
  }

  .slider {
    padding: 0 10px;
  }
}
