.formBox {
  /* border: 1px solid red; */
  height: 300px;
  width: 60%;
  padding: 20px 25px;
  border-radius: 8px;
  /* background-color: #fff; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}
.formBox section {
  margin-bottom: 30px;
}
.inpBox {
  /* margin: 10px 0; */
  width: 100%;
  border: 1px solid gray;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  color: gray;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.heading {
  display: flex;
  justify-content: space-between;
}
.Link {
  text-decoration: none;
  color: black;
  font-size: 0.9rem;
}
.Link span {
  color: #34c89a;
  font-weight: bold;
}

@media only screen and (max-width: 1106px) {
  .heading {
    flex-direction: column;
  }
}
@media only screen and (max-width: 768px) {
  .heading {
    flex-direction: column;
  }
}
@media only screen and (max-width: 375px) {
  .heading {
    flex-direction: column;
  }
}
