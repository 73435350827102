.galleryContainer {
  display: flex;
  flex-wrap: wrap;
}
.image {
  cursor: pointer;
  aspect-ratio: 10 / 8;
  border: 4px solid white;
  position: relative;
}
.imageActive {
  position: relative;
  border: 4px solid  rgb(53, 200, 155) !important;
}

.modal-90w {
  height: 90vh !important;
  overflow: hidden;
}

.checkBox {
  border: 1px solid black;
  width: 18px;
  height: 18px;
  position: relative;
  top: 5px;
  margin-right: 10px;
  border-radius: 5px;
  padding: 3px;
  accent-color: rgb(53, 200, 155);
  background-color: #fff;
}

.imagesContainer {
  height: 80vh;
  overflow-y: scroll;
}
