.mainContainer {
  /* border: 1px solid red; */
  width: 65%;
  margin-left: 35px;
  margin-bottom: 50px;
  border-radius: 8px;
}

.mainContainer section {
  margin-bottom: 20px;
}
.mainContainer .cardSection{
  /* border: 1px solid red; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
}
.heading h2 {
  font-size: 1.7rem;
  font-weight: bold;
}

@media only screen and (max-width: 965px) {
  .mainContainer{
    width: 90%;
  }
}
@media only screen and (max-width: 425px) {
  .mainContainer {
    /* width: 30%; */
    margin-left: 10px;
    margin-top: 10px;
  }
  .heading h2 {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 375px) {
  .heading h2 {
    font-size: 1.4rem;
  }
}
@media only screen and (max-width: 320px) {
  .heading h2 {
    font-size: 1.2rem;
  }
}
