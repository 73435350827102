.parent {
  /* border: 1px solid red; */
  width: 100%;
  /* max-width: 20%; */
  /* min-width: 220px; */
  position: relative;
  margin: 10px auto;
  /* padding: 0; */
}

.child1 {
  width: 100%;
  border-radius: 20px;
  padding: 0;
  height: 220px;
}

.child1 img {
  border-radius: 22px;
}
.child2 {
  border-radius: 20px;
  padding: 20px 15px;
  position: absolute;
  top: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.28);
}
.child2 p {
  font-size: 12px;
}

.content h5 {
  line-height: 30px;
}
.content section {
  border-radius: 4px;
  border: 1px solid #fff;
  position: relative;
  top: 20px;
}

@media only screen and (max-width: 456px) {
  .parent {
    padding: 0;
    margin: 10px auto;
    width: 90%;
  }
  .child2 {
    width: 100%;
  }
}
