.mainBox{
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.mainBox .contentBox{
    /* border: 1px solid red; */
    width: 90%;
}
.mainBox .contentBox .listBox{
    /* border: 1px solid red; */
    margin-left: 20px;
}
.listBox{
    margin: 10px 0;
}
.listBox li{
    margin-left: 20px;
    margin-bottom: 10px;
}