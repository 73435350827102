.section_container {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

.iconBox button {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
}
.iconBox button:hover {
  background-color: #35c89b;
  color: #fff;
  transition: linear 0.5s;
}
.iconBox button svg {
  position: relative;
  bottom: 2px;
}
.prev {
  left: 10px;
  top: 45%;
}
.next {
  right: 10px;
  top: 45%;
}
.imgBox {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 912px) {
  .section_container {
    height: 70vh;
  }
}
@media only screen and (max-width: 900px) {
  .section_container {
    height: 70vh;
  }
}
@media only screen and (max-width: 500px) {
  .section_container {
    height: 60vh;
  }
  .prev,
  .next {
    display: none;
  }
}
/* @media only screen and (max-width: 400px) {
  .section_container {
    height: 30vh;
  }
}
@media only screen and (max-width: 300px) {
  .section_container {
    height: 20vh;
  }
} */
