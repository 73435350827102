.header {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #35c89b;
  color: #fff;
}
.header section {
  display: flex;
  align-items: flex-end;
}
.header img {
  display: block;
  margin: 0 15px;
  font-size: 2em;
  position: relative;
  bottom: 11px;
  width: 30px;
  height: 30px;
}
.header h2 {
  font-weight: 600;
}

@media only screen and (max-width: 837px) {
  .header h2 {
    font-size: 1.4em;
  }
  .header img {
    width: 20px;
    height: 20px;
    bottom: 12px;
  }
}
@media only screen and (max-width: 600px) {
  .header section {
    flex-direction: column;
    align-items: center;
  }
  .header img {
    margin: 5px 0;
    bottom: 4px;
  }
}
@media only screen and (max-width: 500px) {
  .header h2 {
    font-size: 1.1em;
  }
  .header img {
    width: 20px;
    height: 20px;
    bottom: 8px;
  }
}
@media only screen and (max-width: 430px) {
  .header h2 {
    font-size: 1em;
  }
  .header img {
    width: 15px;
    height: 15px;
    bottom: 10px;
  }
}
@media only screen and (max-width: 400px) {
  .header section {
    flex-direction: column;
    align-items: center;
  }
  .header img {
    margin: 5px 0;
    bottom: 4px;
  }
}
