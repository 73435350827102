p {
  margin: 0;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.header button {
  margin: 0 10px;
}
.header h1 {
  font-weight: bolder;
  font-size: 2rem;
}
.timeBox {
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.timeBox h5 {
  font-weight: bolder;
  position: relative;
  top: 2px;
}
.timeBox p {
  font-size: 14px;
  color: gray;
}
.mapAndStatusBox {
  display: flex;
  gap: 1.5rem;
}
.mapBox {
  width: 50%;
  height: 300px;
}

.statusBox h5 {
  font-weight: bolder;
}
.statusBox p {
  font-weight: bold;
  font-size: 14px;
}
.statusBox small {
  font-size: 13px;
  color: gray;
}
@media only screen and (max-width: 992px) {
  .mapAndStatusBox {
    flex-direction: column;
  }
  .mapBox,
  .statusBox {
    width: 100%;
  }
  .header {
  }
  .header button {
    margin: 5px 0;
  }
}
