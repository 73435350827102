* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.cardBox {
  width: 262;
  margin: 0 5px;
  border-radius: 12px;
  border: 1px solid lightgray;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  position: relative;
  border-radius: 12px;
}
.cardImageBox {
  position: relative;
}
.cardImageBox div {
  border-radius: 12px 12px 0 0;
}

.imgBoxText {
  position: absolute;
  top: 0;
  color: #fff8dd;
  width: 100%;
  padding-right: 5px;
  border: 1px solid white;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;

  /* align-items: center; */
  border-radius: 12px 12px 0 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.cardImageBox img {
  border-radius: 12px 12px 0 0;
}
.imgBoxText span {
  font-size: 11px;
}
.imgBoxText section {
  justify-content: space-between;
  /* width: 60px; */
}
.imgChild1 {
  position: relative;
  top: 5px;
}
.imgChild2 {
  height: fit-content;
  border: 1px solid white;
  width: 100px !important;
  padding: 4px;
  border-radius: 5px;
  align-items: center;
}

.child1 {
  /* border: 1px solid red; */
  margin: 0;
  padding: 0;
  /* width: ; */
}
.child1 img {
  border-radius: 50%;
}
.cardContentParent h6,
.child1 p {
  margin: 0;
}
.cardContentParent span {
  font-size: 12px;
  margin-left: 5px;
}
.cardContentParent {
  padding: 15px 15px;
  cursor: pointer;
  height: 110px;
}
.cardContentParent h6 {
  font-size: 12px;
  margin-bottom: 2px;
}
.cardContent {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.child1 p {
  font-size: 12px;
}
.child2 {
  display: flex;
  position: relative;
  /* bottom: 5px; */
}
.child2 span {
  font-size: 11px;
}
.cardBox .fvrtIconBox {
  /* border: 1px solid red; */
  position: absolute;
  z-index: 99;
  right: 2%;
  top: 2%;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .cardBox {
    width: 97%;
  }
}
