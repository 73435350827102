article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.5s linear;
}
article img {
  height: 90vh;
}
article.activeSlide {
  opacity: 1;
  transform: translateX(0);
  z-index: -1;
}
article.lastSlide {
  transform: translateX(-100%);
  z-index: -1;
}
article.nextSlide {
  transform: translateX(100%);
  z-index: -1;
}
@media only screen and (max-width: 912px) {
  article img {
    height: 70vh;
  }
}
@media only screen and (max-width: 500px) {
  article img {
    height: 60vh;
  }
}
/* @media only screen and (max-width: 400px) {
  article img {
    height: 30vh;
  }
}
@media only screen and (max-width: 300px) {
  article img {
    height: 20vh;
  }
} */
