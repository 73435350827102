footer {
  background-color: #35c89b;
  min-height: 100px;
  margin-bottom: -25px;
  position: relative;
  bottom: 0;
}
.footer {
  color: #fff;
  display: flex;
  /* justify-content: space-between; */
  min-height: 100px;
  align-items: center;
  margin: 0 10px;
}
.footer section {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.footer section div {
  /* border: 1px solid red; */
  margin: 0 2px;
  padding: 0 5px;
  font-size: 15px;
  font-weight: 600;
}
.child1 {
  margin: 0;
  /* border: 1px solid red; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: wrap;
  line-height: 30px;
}
.child1 div {
  cursor: pointer;
  /* border: 1px solid red; */
  margin: 10px !important;
}
.child2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.child2 div {
  margin: 0 10px;
}
@media only screen and (max-width: 991px) {
  .footer {
    flex-direction: column;
    padding: 7px 0;
  }
  .footer .child1 {
    width: 100%;
  }
  .footer section div {
    margin: 0;
    font-size: 13px;
  }
  .footer section div img {
    width: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .footer {
    flex-direction: column;
    padding: 7px 0;
    margin: 0;
  }
  .footer section div img {
    width: 30px;
  }
  .child1 {
    flex-direction: column;
  }
}
/* @media only screen and (max-width: 665px) {
  .footer section div {
    margin: 0;
    font-size: 10px;
  }
} */
/* @media only screen and (max-width: 520px) {
  
} */
